<template>
  <div class="w-full flex grow items-center justify-center light-mode">
    <div class="w-full my-3 px-5 ">
      <panel class="text-right p-16 w-full flex flex-col items-center justify-between">
        <h5 class="mb-5 font-bold text-platform-text-color text-5xl">
          {{ 'register.title' | trans }}
        </h5>
      </panel>
    </div>
  </div>
</template>

<script>
import CheckBox from '../../../Components/Global/CheckBox';
import Spinner from '../Spinner';
import Error from '../Error';

export default {
  components: {
    CheckBox,
    Spinner
  },
  props: {
    email: String,
    hash: String,
  },
  data() {
    return {
      form: {
        firstName: '',
        lastName: '',
        email: this.email,
        role: undefined,
        password: '',
        passwordConfirmation: '',
        terms: false,
        marketing: false,
        accepted_terms: false
      },

      profileTypes: [],
      errors: new Error(),
      isLoading: false,
    };
  },
  computed: {
    isSubmittable() {
      if (!this.form.firstName.length) return false;
      if (!this.form.lastName.length) return false;
      if (this.form.role === undefined) return false;
      if (!this.form.password.length) return false;
      if (!this.form.passwordConfirmation.length) return false;

      return this.form.terms;
    }
  },
  created() {
    this.$http.get(Storm.apiUrl + '/account/profile/types').then(response => {
      this.profileTypes = response.data.data;
    }).catch(errors => console.log(errors));
  },
  methods: {
    validateConfirmationPassword() {
      if (this.form.password.length < 8 && this.form.passwordConfirmation.length < 8) {
        this.errors.set({
          password: [this.translate('components/auth/register.error.password-length')]
        });
        setTimeout(() => {
          this.errors.remove('password');
        }, 2000);
      } else if (this.form.passwordConfirmation && this.form.password !== this.form.passwordConfirmation) {
        this.errors.set({
          password: [this.translate('components/auth/register.error.password-match')]
        });
        setTimeout(() => {
          this.errors.remove('password');
        }, 2000);
      } else {
        this.errors.set({
          password: []
        });
      }
    },
    setTerms(payload) {
      this.form.terms = payload;
      if (payload) {
        this.errors.remove('accepted_terms');
      }
    },
    setMarketing(payload) {
      this.form.marketing = payload;
    },
    onSubmit() {
      this.isLoading = true;

      const params = {
        first_name: this.form.firstName,
        last_name: this.form.lastName,
        email: this.form.email,
        role: this.form.role,
        password: this.form.password,
        password_confirmation: this.form.passwordConfirmation,
        accepted_terms: this.form.terms,
        accepted_marketing: this.form.marketing,
        hash: this.hash
      }

      this.$http.post('/register', params)
        .then(response => {
          location.href = response.data.dashboard || '/';
        })
        .catch(errors => {
          this.errors.set(errors.response.data.errors);

          this.isLoading = false;
        })
    }
  }
};
</script>

<style scoped>

</style>
